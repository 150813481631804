import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IVehicleOnlinePanels, IWidgetGraph } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import * as VehiclesDataState from '../../../store/recoil/vehiclesData';
import { IVehicleData } from '../../Vehicles/interfaces';
import Config from '../../../../config';
import * as GraphState from '../../Graphs/GraphState';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';

export const FUEL_LEVEL_LITERS = 'consAbs';

export default function FuelLevelLitersWidget(props: ICustomWidgetProps): JSX.Element {
  const isGraphEnabled = Config.widgets.FuelLevelLitersWidget.enableGraph;
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(23);
  const valueType = 'l';
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const vehicleData = useRecoilValue<IVehicleData | null>(
    VehiclesDataState.vehicleDataById(vehicleId)
  );
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
  const setFuelLevelLitersWidgetGraph = useSetRecoilState<IWidgetGraph | null>(
    GraphState.fuelLevelLitersWidgetGraph
  );
  const shortName = f({ id: 'widget.fuelLevel.valueName.short' });
  const longName = f({ id: 'widget.fuelLevel.valueName.long' });

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.fuelLevelLiters !== null) {
        const roundedValue = Number(onlinePanel.fuelLevelLiters).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleDetail = () => {
    if (isGraphEnabled && typeof widgetData === 'number' && vehicleData) {
      // const rideId = vehicleData.lastRide?.id;
      setFuelLevelLitersWidgetGraph({
        vehicleId: vehicleId || 0,
      });
    }
  };

  return (
    <div
      onClick={props.isMockupMode ? undefined : handleDetail}
      data-cy="fuel-liters-widget"
      style={props.isMockupMode ? undefined : isGraphEnabled ? { cursor: 'pointer' } : {}}
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.fuelLevel.tooltip' }, { valueType }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="fuel-liters-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="fuel-liters-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="fuel-liters-widget-title-size--more-1" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4 data-cy="fuel-liters-widget-title-size-2" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div data-cy="fuel-liters-widget-icon-size-more-1" className="row no-gutters">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className={`e-widget-icon cmd-icon cmd-icon-gas ${isWidgetDisabled(widgetData)}`}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-gas ${isWidgetDisabled(
                  widgetData
                )}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="fuel-liters-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="fuel-liters-widget-icon-size-1"
          className={`e-widget-icon cmd-icon cmd-icon-gas ${isWidgetDisabled(widgetData)}`}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="fuel-liters-widget-value-size-1"
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
    </div>
  );
}
