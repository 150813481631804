import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';

export default function SatellitesWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(10);
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
  const shortName = f({ id: 'widget.satelites.valueName.short' });
  const longName = f({ id: 'widget.satelites.valueName.long' });

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.satellites !== null) {
        const roundedValue = Number(onlinePanel.satellites).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let iconColor = '#04b590';
  if (widgetData !== null && widgetData <= 3) {
    iconColor = '#9ABAEE';
  }
  if (widgetData === null || props.isMockupMode) {
    iconColor = '#C8C8C8';
  }

  return (
    <div
      data-cy="satellites-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.satelites.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="satellites-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="satellites-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="satellites-widget-title-size-more-1" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4 data-cy="satellites-widget-title-size-2" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div data-cy="satellites-widget-icon-size-more-1" className="row no-gutters">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className="e-widget-icon cmd-icon cmd-icon-satelite"
                style={{ color: iconColor }}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className="e-widget-icon e-widget-icon--md cmd-icon cmd-icon-satelite"
                style={{ color: iconColor }}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="satellites-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="satellites-widget-icon-size-1"
          className={`e-widget-icon cmd-icon cmd-icon-satelite ${isWidgetDisabled(widgetData)}`}
          style={{ color: iconColor }}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="satellites-widget-value-size-1"
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType)}
        </div>
      )}
    </div>
  );
}
