import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import UserService from '../../../Services/UserService';
import { IVehicleOnlinePanelState } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import Config from '../../../../config';
import * as OnlinePanelState from '../OnlinePanelState';
import { getWaypointsFromAnnouncements } from '../../RidePlayer/RidePlayerService';
import { IAnnouncement } from '../../RidePlayer/interfaces';
import RemoveButton from './RemoveButton';

export default function WaypointWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<string[]>([
    'Waypoint 1',
    'Waypoint 2',
    'Waypoint 3',
    'Waypoint 4',
    'Waypoint 5',
    'Waypoint 6',
  ]);

  const onlinePanelData = useRecoilValue<IVehicleOnlinePanelState | null>(
    OnlinePanelState.onlinePanelForSelectedVehicle
  );

  React.useMemo(() => {
    if (!props.isMockupMode) {
      if (onlinePanelData) {
        const userId = UserService.getUserId();

        let waypointsForUser: string[] = [];

        if (onlinePanelData.announcements && userId) {
          waypointsForUser = getWaypointsFromAnnouncements(onlinePanelData.announcements).map(
            (item: IAnnouncement) => item.title || ''
          );
        }

        setWidgetData(waypointsForUser);
      }
    }
  }, [onlinePanelData, props.isMockupMode]);

  let widgetTooltip = f({ id: 'widget.waypoint.tooltip' });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  return (
    <div
      data-cy="waypoint-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1
          ? 'b-widget--alignLeft b-widget--carInfo'
          : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="waypoint-card-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <i
          data-cy="waypoint-widget-icon-size-1-row-1"
          className="e-widget-icon cmd-icon cmd-icon-waypoint"
          title={widgetData.join('\n')}
          style={
            widgetData.length > 0 && !props.isMockupMode
              ? { color: Config.widgetColors.waypoint.active }
              : {}
          }
        />
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 1 && (
        <>
          <i
            data-cy="waypoint-widget-icon-size-4-row-1"
            className="e-widget-icon e-widget-icon--md cmd-icon cmd-icon-waypoint"
            style={
              widgetData.length > 0 && !props.isMockupMode
                ? { color: Config.widgetColors.waypoint.active }
                : {}
            }
          />
          {widgetData.length > 0 && (
            <ul>
              {widgetData.length <= 3 &&
                widgetData.map((alarm: string) => (
                  <li key={alarm} title={alarm}>
                    <span>{alarm}</span>
                  </li>
                ))}
              {widgetData.length > 3 &&
                widgetData.map(
                  (alarm: string, index: number) =>
                    index < 2 && (
                      <li key={alarm} title={alarm}>
                        <span>{alarm}</span>
                      </li>
                    )
                )}
              {widgetData.length > 3 && <li>+{widgetData.length - 2}</li>}
            </ul>
          )}
          {widgetData.length === 0 && <>{f({ id: 'widget.waypoint.noActiveWaypoints' })}</>}
        </>
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 2 && (
        <>
          <i
            data-cy="waypoint-widget-icon-size-4-row-2"
            className="e-widget-icon e-widget-icon--md cmd-icon cmd-icon-waypoint"
            style={
              widgetData.length > 0 && !props.isMockupMode
                ? { color: Config.widgetColors.waypoint.active }
                : {}
            }
          />
          {widgetData.length > 0 && (
            <ul>
              {widgetData.length <= 5 &&
                widgetData.map((alarm: string) => (
                  <li key={alarm} title={alarm}>
                    <span>{alarm}</span>
                  </li>
                ))}
              {widgetData.length > 5 &&
                widgetData.map(
                  (alarm: string, index: number) =>
                    index < 4 && (
                      <li key={alarm} title={alarm}>
                        <span>{alarm}</span>
                      </li>
                    )
                )}
              {widgetData.length > 5 && (
                <li
                  title={widgetData.filter((_alarm: string, index: number) => index > 3).join('\n')}
                >
                  +{widgetData.length - 4}
                </li>
              )}
            </ul>
          )}
          {widgetData.length === 0 && <>{f({ id: 'widget.waypoint.noActiveWaypoints' })}</>}
        </>
      )}
    </div>
  );
}
