/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { ICustomWidgetProps } from './Widget';
import Config from '../../../../config';
import * as VehiclesDataState from '../../../store/recoil/vehiclesData';
import * as VehiclesState from '../../../store/recoil/vehicles';
import * as OnlinePanelState from '../OnlinePanelState';
import IconService from '../../../Services/IconService';
import { IVehicleData, IVehicles, TVehicleStatus } from '../../Vehicles/interfaces';
import { PRIVATE_STATUS } from '../../Navigator/constants';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip } from './WidgetService';

interface IWidgetData {
  status: string;
  licenseNumber: string;
  vehicleName: string;
  driverName: string | null;
  vehicleTypeId: number;
  isPrivate: boolean;
}

export default function VehicleCardWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<IWidgetData>({
    status: 'STOP',
    licenseNumber: 'BL123AB',
    vehicleName: 'BA Technik IT Roomster 2',
    driverName: 'Ferdinand Hečko',
    vehicleTypeId: 1,
    isPrivate: false,
  });
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const vehicleDataFromNavigator = useRecoilValue<IVehicleData | null>(
    VehiclesDataState.vehicleDataById(vehicleId)
  );
  const vehicleFromNavigator = useRecoilValue<IVehicles>(VehiclesState.vehicles);

  const translateStatus = (vehicleStatus: string | null) => {
    if (!vehicleStatus) {
      return '';
    }
    return `${f({ id: `widget.status.${vehicleStatus}` })},`;
  };

  const statusColor = (vehicleStatus: string) => {
    let color = '';
    switch (vehicleStatus) {
      case 'STOP': {
        color = Config.widgetColors.vehicleCard.STOP;
        break;
      }
      case 'DRIVING': {
        color = Config.widgetColors.vehicleCard.DRIVING;
        break;
      }
      case 'NO_GPS': {
        color = Config.widgetColors.vehicleCard.NO_GPS;
        break;
      }
      case 'NO_GSM': {
        color = Config.widgetColors.vehicleCard.NO_GSM;
        break;
      }
      default: {
        color = Config.widgetColors.vehicleCard.UNKNOWN;
        break;
      }
    }
    return color;
  };

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const vehicleDataForSelectedVehicle = vehicleDataFromNavigator;
      const vehicleForSelectedVehicle = vehicleFromNavigator[vehicleId as number];
      if (vehicleDataForSelectedVehicle && vehicleForSelectedVehicle) {
        setWidgetData({
          status: vehicleDataForSelectedVehicle.status,
          driverName: vehicleForSelectedVehicle.driver.name,
          vehicleName: vehicleForSelectedVehicle.name,
          licenseNumber: vehicleForSelectedVehicle.licenceNumber,
          vehicleTypeId: vehicleForSelectedVehicle.vehicleTypeId,
          isPrivate: vehicleForSelectedVehicle.drivingMode === 'private',
        });
      }
    }
  }, [props.isMockupMode, vehicleDataFromNavigator, vehicleFromNavigator, vehicleId]);

  const getStatusColor = (status: TVehicleStatus): string => {
    return Config.widgetColors.vehicleCard[status];
  };

  const vehicleIconSvg = (
    <svg
      width="55"
      viewBox={vehicleId === Config.mucha ? '0 0 35 35' : '4 0 35 35'}
      version="1.1"
      data-cy={`navigator-icon-${widgetData.vehicleTypeId}-${widgetData.status}`}
    >
      <path
        id="path"
        d={IconService.getPath(vehicleId === Config.mucha ? 999 : widgetData.vehicleTypeId, 0)}
        fill={`${
          widgetData.status === PRIVATE_STATUS || widgetData.status === null || widgetData.isPrivate
            ? '#737373'
            : getStatusColor(widgetData.status as TVehicleStatus)
        }`}
        fillOpacity="1"
        stroke="none"
      />
    </svg>
  );

  return (
    <div
      data-cy="vehicle-card-widget"
      className={`b-widget b-widget--drivers-card b-widget--${props.data.rowSize}x${
        props.data.columnSize
      } ${props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.vehicleCard.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="vehicle-card-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 3 && (
        <>
          <div className="info" data-cy="vehicle-card-widget-licence-number-size-3">
            <strong>
              <span style={!props.isMockupMode ? { color: statusColor(widgetData.status) } : {}}>
                {translateStatus(widgetData.status)}
              </span>{' '}
              {widgetData.licenseNumber}
            </strong>
          </div>
          <div className="info" data-cy="vehicle-card-widget-vehicle-name-size-3">
            <div className="text-truncate" style={{ width: '100%' }} title={widgetData.vehicleName}>
              <strong>{widgetData.vehicleName}</strong>
            </div>
          </div>
          <div className="info" data-cy="vehicle-card-widget-driver-name-size-3">
            <div
              className="text-truncate"
              style={{ width: '100%' }}
              title={widgetData.driverName || ''}
            >
              {widgetData.driverName}
            </div>
          </div>
        </>
      )}
      {props.data.columnSize === 4 && props.data.hasIcon && (
        <div className="row no-gutters">
          <div className="col-auto">
            {/* <i className="e-widget-icon e-widget-icon--md cmd-icon cmd-icon-car" /> */}
            <div className="rotate90">{vehicleIconSvg}</div>
          </div>
          <div className="col">
            <div className="info" data-cy="vehicle-card-widget-licence-number-size-4">
              <strong>
                <span style={!props.isMockupMode ? { color: statusColor(widgetData.status) } : {}}>
                  {translateStatus(widgetData.status)}
                </span>{' '}
                {widgetData.licenseNumber}
              </strong>
            </div>
            <div className="info" data-cy="vehicle-card-widget-vehicle-name-size-4">
              <div
                className="text-truncate"
                style={{ width: '100%' }}
                title={widgetData.vehicleName}
              >
                <strong>{widgetData.vehicleName}</strong>
              </div>
            </div>
            <div className="info" data-cy="vehicle-card-widget-driver-name-size-4">
              <div
                className="text-truncate"
                style={{ width: '100%' }}
                title={widgetData.driverName || ''}
              >
                {widgetData.driverName}
              </div>
            </div>
          </div>
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="vehicle-card-widget-icon"
          className="e-widget-icon cmd-icon cmd-icon-car"
          style={
            !props.isMockupMode
              ? { color: statusColor(widgetData.status), marginRight: 0 }
              : { marginRight: 0 }
          }
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="vehicle-card-widget-licence-number-size-1"
          className="e-widget-value"
          style={{ fontSize: '13px' }}
        >
          <strong>{widgetData.licenseNumber}</strong>
        </div>
      )}
    </div>
  );
}
