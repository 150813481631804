import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  FRONT_LEFT_DOOR_OPENED,
  FRONT_RIGHT_DOOR_OPENED,
  HOOD_OPENED,
  REAR_LEFT_DOOR_OPENED,
  TRUNK_OPENED,
  REAR_RIGHT_DOOR_OPENED,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import SvgIconService from '../../../Services/SvgIconService';
import { IAnnouncement } from '../../RidePlayer/interfaces';
import RemoveButton from './RemoveButton';

const carInfo = SvgIconService.carInfo();

interface IWidgetData {
  fl: boolean | null;
  fr: boolean | null;
  hd: boolean | null;
  rl: boolean | null;
  rr: boolean | null;
  tr: boolean | null;
}

export default function DoorsWidget(props: ICustomWidgetProps) {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<IWidgetData | null>({
    fl: false,
    fr: false,
    hd: true,
    rl: true,
    rr: false,
    tr: false,
  });

  const doorEvents = useRecoilValue<IAnnouncement[]>(
    OnlinePanelState.doorsEventsForSelectedVehicle
  );

  React.useMemo(() => {
    if (!props.isMockupMode) {
      if (doorEvents.length > 0) {
        const doors: IWidgetData = {
          fl: !!doorEvents.find((item: IAnnouncement) => item.title === FRONT_LEFT_DOOR_OPENED),
          fr: !!doorEvents.find((item: IAnnouncement) => item.title === FRONT_RIGHT_DOOR_OPENED),
          rl: !!doorEvents.find((item: IAnnouncement) => item.title === REAR_LEFT_DOOR_OPENED),
          rr: !!doorEvents.find((item: IAnnouncement) => item.title === REAR_RIGHT_DOOR_OPENED),
          hd: !!doorEvents.find((item: IAnnouncement) => item.title === HOOD_OPENED),
          tr: !!doorEvents.find((item: IAnnouncement) => item.title === TRUNK_OPENED),
        };
        setWidgetData(doors);
      } else {
        const doors: IWidgetData = {
          fl: false,
          fr: false,
          rl: false,
          rr: false,
          hd: false,
          tr: false,
        };
        setWidgetData(doors);
      }
    }
  }, [doorEvents, props.isMockupMode]);

  let widgetTooltip = f({ id: 'widget.doors.tooltip' });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  const isAllDoorsClosed = (data: IWidgetData): boolean => {
    if (!data.fl && !data.fr && !data.hd && !data.rl && !data.rr && !data.tr) {
      return true;
    }
    return false;
  };

  const getTooltip = (data: IWidgetData | null): string => {
    let text = '';
    if (!data) {
      text = f({ id: 'widget.doors.noData' });
      return text;
    }
    if (data.fl) {
      text += `${f({ id: 'widget.doors.fl' })}\n`;
    }
    if (data.fr) {
      text += `${f({ id: 'widget.doors.fr' })}\n`;
    }
    if (data.rl) {
      text += `${f({ id: 'widget.doors.rl' })}\n`;
    }
    if (data.rr) {
      text += `${f({ id: 'widget.doors.rr' })}\n`;
    }
    if (data.hd) {
      text += `${f({ id: 'widget.doors.hd' })}\n`;
    }
    if (data.tr) {
      text += `${f({ id: 'widget.doors.tr' })}\n`;
    }
    return text;
  };

  return (
    <div
      data-cy="doors-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 2
          ? 'b-widget--alignLeft b-widget--carInfo'
          : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="doors-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <div
          data-cy="doors-widget-size-row-1"
          className="e-car-info"
          title={getTooltip(widgetData)}
        >
          {widgetData && widgetData.hd && (
            <span className={`front-hood ${widgetData.hd && !props.isMockupMode && 'active'}`} />
          )}
          <img src={carInfo} alt="" title="" />
          {widgetData && widgetData.tr !== null && (
            <span className={`car-truck ${widgetData.tr && !props.isMockupMode && 'active'}`} />
          )}
          {widgetData && widgetData.fr !== null && (
            <span
              data-cy="doors-widget-front-right-door"
              className={`doors front-right-door ${
                widgetData.fr && !props.isMockupMode && 'active'
              }`}
            />
          )}
          {widgetData && widgetData.fl !== null && (
            <span
              data-cy="doors-widget-front-left-door"
              className={`doors front-left-door ${
                widgetData.fl && !props.isMockupMode && 'active'
              }`}
            />
          )}
          {widgetData && widgetData.rr !== null && (
            <span
              data-cy="doors-widget-rear--right-door"
              className={`doors rear-right-door ${
                widgetData.rr && !props.isMockupMode && 'active'
              }`}
            />
          )}
          {widgetData && widgetData.rl !== null && (
            <span
              data-cy="doors-widget-rear-left-door"
              className={`doors rear-left-door ${widgetData.rl && !props.isMockupMode && 'active'}`}
            />
          )}
        </div>
      )}
      {props.data.columnSize === 2 && props.data.rowSize === 2 && (
        <div
          data-cy="doors-widget-size-2-row-2"
          className="e-car-info"
          title={getTooltip(widgetData)}
        >
          {widgetData && widgetData.hd && (
            <span className={`front-hood ${widgetData.hd && !props.isMockupMode && 'active'}`} />
          )}
          <img src={carInfo} alt="" />
          {widgetData && widgetData.tr !== null && (
            <span className={`car-truck ${widgetData.tr && !props.isMockupMode && 'active'}`} />
          )}
          {widgetData && widgetData.fr !== null && (
            <span
              data-cy="doors-widget-front-right-door"
              className={`doors front-right-door ${
                widgetData.fr && !props.isMockupMode && 'active'
              }`}
            />
          )}
          {widgetData && widgetData.fl !== null && (
            <span
              data-cy="doors-widget-front-left-door"
              className={`doors front-left-door ${
                widgetData.fl && !props.isMockupMode && 'active'
              }`}
            />
          )}
          {widgetData && widgetData.rr !== null && (
            <span
              data-cy="doors-widget-rear-right-door"
              className={`doors rear-right-door ${
                widgetData.rr && !props.isMockupMode && 'active'
              }`}
            />
          )}
          {widgetData && widgetData.rl !== null && (
            <span
              data-cy="doors-widget-rear-left-door"
              className={`doors rear-left-door ${widgetData.rl && !props.isMockupMode && 'active'}`}
            />
          )}
        </div>
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 2 && (
        <>
          <div
            data-cy="doors-widget-size-4-row-2"
            className="e-car-info"
            title={getTooltip(widgetData)}
          >
            {widgetData && widgetData.hd && (
              <span className={`front-hood ${widgetData.hd && !props.isMockupMode && 'active'}`} />
            )}
            <img src={carInfo} alt="" title="" />
            {widgetData && widgetData.tr !== null && (
              <span className={`car-truck ${widgetData.tr && !props.isMockupMode && 'active'}`} />
            )}
            {widgetData && widgetData.fr !== null && (
              <span
                data-cy="doors-widget-front-right-door"
                className={`doors front-right-door ${
                  widgetData.fr && !props.isMockupMode && 'active'
                }`}
              />
            )}
            {widgetData && widgetData.fl !== null && (
              <span
                data-cy="doors-widget-front-left-door"
                className={`doors front-left-door ${
                  widgetData.fl && !props.isMockupMode && 'active'
                }`}
              />
            )}
            {widgetData && widgetData.rr !== null && (
              <span
                data-cy="doors-widget-rear-right-door"
                className={`doors rear-right-door ${
                  widgetData.rr && !props.isMockupMode && 'active'
                }`}
              />
            )}
            {widgetData && widgetData.rl !== null && (
              <span
                data-cy="doors-widget-rear-left-door"
                className={`doors rear-left-door ${
                  widgetData.rl && !props.isMockupMode && 'active'
                }`}
              />
            )}
          </div>
          {widgetData && !isAllDoorsClosed(widgetData) ? (
            <ul>
              {widgetData.fl && <li>{f({ id: 'widget.doors.fl' })}</li>}
              {widgetData.fr && <li>{f({ id: 'widget.doors.fr' })}</li>}
              {widgetData.rl && <li>{f({ id: 'widget.doors.rl' })}</li>}
              {widgetData.rr && <li>{f({ id: 'widget.doors.rr' })}</li>}
              {widgetData.hd && <li>{f({ id: 'widget.doors.hd' })}</li>}
              {widgetData.tr && <li>{f({ id: 'widget.doors.tr' })}</li>}
            </ul>
          ) : (
            <>{f({ id: 'widget.doors.allDoorsClosed' })}</>
          )}
        </>
      )}
    </div>
  );
}
