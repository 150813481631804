/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AdBlueIcon } from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import * as GraphState from '../../Graphs/GraphState';
import Config from '../../../../config';
import { IVehicleData } from '../../Vehicles/interfaces';
import * as VehiclesDataState from '../../../store/recoil/vehiclesData';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';

export const AD_BLUE_LEVEL_PERCENTAGE = 'adblueLevelPerc';

const AD_BLUE_LOW_LEVEL_COLOR = '#B50536';
const AD_BLUE_NORMAL_LEVEL_COLOR = '#3D72C8';
const AD_BLUE_DISABLED_COLOR = '#C8C8C8';

export default function AdBlueWidget(props: ICustomWidgetProps): JSX.Element {
  const isGraphEnabled = Config.widgets.AdBlueWidget.enableGraph;
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(67);
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
  const setAdBluePercWidgetGraph = useSetRecoilState<OnlinePanelState.IWidgetGraph | null>(
    GraphState.adBluePercWidgetGraph
  );
  const vehicleData = useRecoilValue<IVehicleData | null>(
    VehiclesDataState.vehicleDataById(vehicleId)
  );
  const valueType = '%';
  const shortName = f({ id: 'widget.adBlueLevel.valueName.short' });
  const longName = f({ id: 'widget.adblueLevel.valueName.long' });
  const LOW_PERCENTAGE = 10;

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (
        onlinePanel &&
        onlinePanel.adBlueLevel !== null &&
        typeof vehicleId === 'number' &&
        typeof onlinePanel.adBlueLevel === 'number'
      ) {
        const roundedValue = Number(onlinePanel.adBlueLevel).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleDetail = () => {
    if (typeof widgetData === 'number' && widgetData !== null) {
      if (isGraphEnabled && typeof widgetData === 'number' && vehicleData) {
        setAdBluePercWidgetGraph({
          vehicleId: vehicleId || 0,
        });
      }
    }
  };

  const handleWidgetColor = () => {
    if (isWidgetDisabled(widgetData)) {
      return AD_BLUE_DISABLED_COLOR;
    }
    if (widgetData !== null && !props.isMockupMode && widgetData < LOW_PERCENTAGE) {
      return AD_BLUE_LOW_LEVEL_COLOR;
    }
    return AD_BLUE_NORMAL_LEVEL_COLOR;
  };

  return (
    <div
      onClick={props.isMockupMode ? undefined : handleDetail}
      style={props.isMockupMode ? undefined : isGraphEnabled ? { cursor: 'pointer' } : {}}
      data-cy="adblue-percentage-widget"
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.adblueLevel.valueName.long' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="adblue-percentage-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
        // <a
        //   data-cy="adblue-percentage-widget-in-grid"
        //   style={{
        //     position: 'absolute',
        //     top: '1px',
        //     right: '1px',
        //     left: 'inherit',
        //     transform: 'inherit',
        //   }}
        //   href="#"
        //   title={f({ id: 'widgets.removeFromGrid.tooltip' })}
        //   className="cmd-icon cmd-icon-remove-sm close"
        //   data-grid-name={props.data.gridName}
        //   onClick={props.onRemoveCallback}
        // />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="adblue-percentage-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="adblue-percentage-widget-title-size-more-1" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4 data-cy="adblue-percentage-widget-title-size-2" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div className="row no-gutters" data-cy="adblue-percentage-widget-icon-size-more-1">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <AdBlueIcon size="24px" className="e-widget-icon" color={` ${handleWidgetColor()}`} />
            )}
            {props.data.columnSize === 3 && (
              <AdBlueIcon
                size="40px"
                className="e-widget-icon e-widget-icon--md"
                color={` ${handleWidgetColor()}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="adblue-percentage-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <AdBlueIcon size="24px" className="e-widget-icon" color={` ${handleWidgetColor()}`} />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="adblue-percentage-widget-value-size-1"
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
    </div>
  );
}
