import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import UserService from '../../../Services/UserService';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import { getAlarmsFromAnnouncements } from '../../RidePlayer/RidePlayerService';
import { IAnnouncement } from '../../RidePlayer/interfaces';
import RemoveButton from './RemoveButton';

export default function AlarmWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<string[]>([
    'Alarm 1 (99m)',
    'Alarm 2 (23m)',
    'Alarm 3 (4m)',
    'Alarm 4 (43m)',
    'Alarm 5 (67m)',
    'Alarm 6 (85m)',
  ]);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel) {
        const userId = UserService.getUserId();

        let alarmsForUser: string[] = [];

        if (onlinePanel && userId) {
          alarmsForUser = getAlarmsFromAnnouncements(onlinePanel.announcements, true).map(
            (vehicleAlarm: IAnnouncement) => vehicleAlarm.title || ''
          );
        }

        setWidgetData(alarmsForUser);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let widgetTooltip = f({ id: 'widget.alarm.tooltip' });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  return (
    <div
      data-cy="alarm-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1
          ? 'b-widget--alignLeft b-widget--carInfo'
          : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="alarm-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <i
          data-cy="alarm-widget-value-size-1-row-1"
          className={`e-widget-icon cmd-icon cmd-icon-alarm ${
            widgetData.length > 0 && !props.isMockupMode ? 'active' : ''
          }`}
          title={widgetData.join('\n')}
        />
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 1 && (
        <>
          <i
            data-cy="alarm-widget-value-size-4-row-1"
            className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-alarm ${
              widgetData.length > 0 && !props.isMockupMode ? 'active' : ''
            }`}
          />
          {widgetData.length > 0 && (
            <ul>
              {widgetData.length <= 3 &&
                widgetData.map((alarm: string) => (
                  <li key={alarm} title={alarm}>
                    <span>{alarm}</span>
                  </li>
                ))}
              {widgetData.length > 3 &&
                widgetData.map(
                  (alarm: string, index: number) =>
                    index < 2 && (
                      <li key={alarm} title={alarm}>
                        <span>{alarm}</span>
                      </li>
                    )
                )}
              {widgetData.length > 3 && <li>+{widgetData.length - 2}</li>}
            </ul>
          )}
          {widgetData.length === 0 && <>{f({ id: 'widget.alarm.noActiveAlarms' })}</>}
        </>
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 2 && (
        <>
          <i
            data-cy="alarm-widget-value-size-4-row-2"
            className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-alarm ${
              widgetData.length > 0 && !props.isMockupMode ? 'active' : ''
            }`}
          />
          {widgetData.length > 0 && (
            <ul>
              {widgetData.length <= 5 &&
                widgetData.map((alarm: string) => (
                  <li key={alarm} title={alarm}>
                    <span>{alarm}</span>
                  </li>
                ))}
              {widgetData.length > 5 &&
                widgetData.map(
                  (alarm: string, index: number) =>
                    index < 4 && (
                      <li key={alarm} title={alarm}>
                        <span>{alarm}</span>
                      </li>
                    )
                )}
              {widgetData.length > 5 && (
                <li
                  title={widgetData.filter((_alarm: string, index: number) => index > 3).join('\n')}
                >
                  +{widgetData.length - 4}
                </li>
              )}
            </ul>
          )}
          {widgetData.length === 0 && <>{f({ id: 'widget.alarm.noActiveAlarms' })}</>}
        </>
      )}
    </div>
  );
}
